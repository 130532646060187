import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import * as tf from '@tensorflow/tfjs';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import * as mobilenet from  '@tensorflow-models/mobilenet';
import { ModeloService } from "../../../services/modelo/modelo.service";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from "@angular/common";
import { VideoReconocimientoComponent } from "../../../shared/video-reconocimiento/video-reconocimiento.component";

@Component({
    selector: 'creacion-modelo',
    standalone: true,
    imports: [ReactiveFormsModule,MatIconModule, CommonModule, VideoReconocimientoComponent],
    templateUrl: './creacion-modelo.component.html'
})
export class CreacionModeloComponent implements OnInit, OnDestroy{
    resultadoVideo!: string;
    classifier: knnClassifier.KNNClassifier;
    labels: any = [];
    labelsArray:any = [];
    showForm: boolean = false;
    form!: FormGroup;
    modelos:any;
    inputControl = new FormControl("");
    private isAlive2= true;
    videoRef!: ElementRef<HTMLVideoElement>;
    constructor(public serviceModel: ModeloService,private fb: FormBuilder){
        this.form = this.fb.group({
            label: ['', Validators.required]
          });
        this.classifier = knnClassifier.create()
        this.app();
    }
    ngOnDestroy(): void {
        this.isAlive2=false;
    }
    ngOnInit() {
        console.log(this.videoRef);
    }

    net: any;
    webcam: any;
    async app(){
        this.net = await mobilenet.load();
        this.webcam = await tf.data.webcam(this.videoRef.nativeElement);
        while(this.isAlive2){
            const img = await this.webcam.capture();
            const result = await this.net.classify(img);
            const activation = this.net.infer(img, "conv_preds");
            let result2:any;
            try {
                result2 = await this.classifier.predictClass(activation);
                console.log(result2)
                const classes = ["Undefined"].concat(this.labelsArray)
                console.log(classes)
                this.resultadoVideo="Predicción: " + classes[result2.label] + ", Probabilidad: " + (result2.confidences[result2.label].toFixed(2)); 
            } catch (error) {
                console.log("Modelo no configurado aún"+error);
                result2= {};
                this.resultadoVideo="Modelo no entrenado"                
            }
            img.dispose();
            await tf.nextFrame();
            }
    }
    
    obtenerVideoElement(element: ElementRef<HTMLVideoElement>){
        this.videoRef=element
    }

    async addExample(classId:any){
        console.log('Added example');
        const img = await this.webcam.capture();
        const activation = this.net.infer(img, true);
        this.classifier.addExample(activation, classId);
        img.dispose();
    }

    saveKnn() {
        this.serviceModel.guardarModelos(this.classifier, this.inputControl.value).subscribe(result=>{
            console.log(result)
            this.inputControl.reset()
            this.labels=[];
            this.labelsArray=[];
            this.resultadoVideo="";
        })
        this.serviceModel.guardarJsonModelo(this.labels.reduce((acc:any, val:any)=>acc+val.nombre+",", "").slice(0,-1), this.inputControl.value!).subscribe(result=>{
            console.log(result);
        })
};

    loadKnn = () => {
        this.serviceModel.getModelos().subscribe(result=>{
            this.modelos = result;
            console.log(this.modelos);
        });
    }

    toggleForm(){
        this.showForm = !this.showForm;
    }

    submitForm(): void {
        const posicion=this.labels.length
        this.labels.push({"id":posicion, "nombre": this.form.value.label})
        console.log(this.labels)
        this.labelsArray.push(this.form.value.label)
        this.toggleForm(); // Optionally hide the form after submission
    }
}

