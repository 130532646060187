<section>
    <app-video-reconocimiento (videoElement)="obtenerVideoElement($event)" [prediccion]='resultadoVideo' ></app-video-reconocimiento>
    <button class="btn btn-secondary" (click)="toggleForm()">
      <mat-icon aria-hidden="false" aria-label="Example add_circle icon" fontIcon="add_circle"></mat-icon>
    </button>
    <div *ngIf="showForm">
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <label for="label">Etiqueta:</label>
        <input type="text" id="name" formControlName="label">
        <div *ngIf="form.get('label')!.invalid && form.get('label')!.touched">
          Se requiere una etiqueta
        </div>
    
        <button type="submit" [disabled]="form.invalid">Crear</button>
      </form>
    </div>
    <div class="d-flex flex-row mb-3">
      <div class="p-2" *ngFor="let label of labels">
        <button id="clase-a" type="button" class="btn btn-primary" (click)="addExample(label.id+1)"> {{label.nombre}} </button>
      </div>
    </div>

    <script src="../../index.js"> </script>
    <br/>
    <br />
    <div>
      <input id="inputControl" type="text" [formControl]="inputControl">
    </div>
    <br />
    <button id="saveKnn" class="btn btn-secondary" type="button" (click)="saveKnn();">Guardar Modelo</button>
    <button id="loadKnn" class="btn btn-secondary" type="button" (click)="loadKnn();">Cargar Modelo</button>
    <br />


  </section>